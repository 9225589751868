import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { localized, msg } from '@lit/localize';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './footnote-item.scss?inline';
import '../link/link';

/**
 * @summary A footnote item is an item in the footnotes list.
 * It requires an id and href to properly link to a piece of content.
 *
 * @slot default This slot holds the footnote text
 */
@customElement('pds-footnote-item', {
  category: 'component',
  type: 'component',
  styles,
})
@localized()
export class PdsFootnoteItem extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.setLocale();
  }

  /**
   * Style variant
   * - **default** renders the standard footnote-item used for footnotes
   */
  @property()
  variant: 'default';

  // This property needs to be reflected so that our `:target` logic works for highlighting
  // TODO: We should change that to be a class or something
  @property({ type: String, reflect: true })
  id: string = '';

  @property({ type: String })
  href: string = '';

  @property({ type: String })
  ariaLabel: string = msg('Back to content');

  /**
   * Focuses on previous item
   * For all `pds-link` components in the light DOM, match the footnote
   * item href and pds-link id.
   * If they match, focus on the `pds-c-link`. Set timeout is
   * needed to occur slightly after the link click
   */
  focusOnPreviousItem() {
    const allLinks = document.querySelectorAll('pds-footnote-link');
    allLinks.forEach((element) => {
      if (this.href === `#${element.id}`) {
        setTimeout(() => {
          /* Optional chaining prevents ability to get full jest coverage - https://github.com/istanbuljs/istanbuljs/issues/516 */
          /* istanbul ignore next */
          element.shadowRoot
            ?.querySelector<HTMLAnchorElement>('.pds-c-footnote-link')
            ?.focus();
        }, 1);
      }
    });
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`
      <li class="${this.getClass()}" id="${this.id}">
        <div class="pds-c-footnote-item--highlight-container">
          <slot>This is a footnote</slot>
          <pds-link
            @click=${() => this.focusOnPreviousItem()}
            class="pds-c-footnote-item--backlink"
            href="${this.href}"
            ariaLabel="${this.ariaLabel}"
            >&#8617;</pds-link
          >
        </div>
      </li>
    `;
  }
}
