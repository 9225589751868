import * as React from 'react';
import { PdsVideoPlayer as PdsVideoPlayerElement } from '@principal/design-system';
import { PdsVideoPlayerProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsVideoPlayer = createPdsReactComponent<PdsVideoPlayerProps>()({
  tagName: 'pds-video-player',
  elementClass: PdsVideoPlayerElement,
  react: React,
});
