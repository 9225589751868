import { configureLocalization } from '@lit/localize';
import { sourceLocale, targetLocales } from './locale-codes';

export const { getLocale, setLocale } = configureLocalization({
  sourceLocale,
  targetLocales,
  loadLocale: (locale: string) => import(`./locales/${locale}.ts`),
});

/**
 * Set the locale for language localization from lang attribute in DOM
 */
export const setLocaleFromLangAttr = async (element: HTMLElement) => {
  if (element.closest('[lang]')?.getAttribute('lang') === 'es') {
    await setLocale('es-419');
  } else {
    await setLocale(sourceLocale);
  }
};
